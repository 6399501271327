.menu {
    border-right: none !important;
    background-color: #F8F9FA !important; /* Default background color */
    margin-top: 1rem;
}

.menuItem {
    border-right: none !important;
    font-weight: normal !important;
}

.activeMenuItem {
    background-color: white !important;
    color: #2D796D !important;
    font-weight: bold !important;
}

.activeMenuItem .ant-menu-item-icon {
    color: #2D796D !important;
}
/* .sidebar {
    background-color: #f8f9fa !important;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width 0.3s; 
  }
   */
  .sidebarCollapsed {
    max-width: 80px !important; /* Adjust as needed */
    min-width: 80px !important; /* Adjust as needed */
    width: 80px !important; /* Adjust as needed */
  }
  
  .sidebarExpanded {
    max-width: 265px !important; /* Adjust as needed */
    min-width: 265px !important; /* Adjust as needed */
    width: 265px !important; /* Adjust as needed */
  }
  