/* Media query for mobile screens */
@media (max-width: 768px) {
    .ant-form-item-label>label {
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    /* Input Styling */
    .ant-input,
    .select,
    .input,
    .ant-input-password {
        border-radius: 8px;
        /* padding: 6px; */
        height: 2px;
    }

    .ant-form-item.css-dev-only-do-not-override-p8b6i.ant-form-item-has-success {
        margin-bottom: 0px;
    }
}