.tableRow {
    &:hover {
      background-color: #fafafa;
    }
  }
  
  .tableCell {
    /* padding: 10px; */
    text-align: left;
  }
  /* .actions {
    display: flex;
    justify-content: start;
    align-items: center;
}

.icon {
    cursor: pointer;
    margin: 0 10px;
} */
