.tableRow {
    &:hover {
      background-color: #fafafa;
    }
  }
  
  .tableCell {
    text-align: start;
    padding: 10px;
}

/* .actions {
    display: flex;
    justify-content: start;
    align-items: center;
}

.icon {
    cursor: pointer;
    margin: 0 10px;
} */
