.tableRow {
  &:hover {
    background-color: #fafafa;
  }
}

.tableCell {
  /* padding: 10px; */
  text-align: left;
}

.ant-drawer-body {
  padding: 10px 0 !important;
}
/* .actions {
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0 10px;
}

.icon {
  cursor: pointer;
  margin: 0 10px;
} */
