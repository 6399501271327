.tableRow {
    &:hover {
      background-color: #fafafa;
    }
  }
  
  .tableCell {
    /* padding: 10px; */
    text-align: left;
  }
