.dashboardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 16px;
    width: 100%;
    padding: 0px 0px 40px 0px;
}

.dashboardCard :global(.ant-card-body) {
    border-radius: 8px;
}

.cardBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    height: 80px;
    width: 100%;
}

.icon {
    width: 2.5rem;
    height: 2.5rem;
    align-items: start;
}

.dashboardContainer {
    padding-left: 0px;
}