/* Form Container Styling */

/* Label Styling */
.ant-form-item-label>label {
  font-weight:600;
  font-size: large !important;
}

/* Input Styling */
.ant-input,
.ant-input-password {
  border-radius: 8px;
  padding: 10px;
}

/* General Styling */
body {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}

.width-100{
  width: 100%;
}

.ant-form-item-explain-error {
  font-size: small;
}


/* Media query for mobile screens */
@media (max-width: 767px) {
  .ant-form-item-label > label {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  /* Input Styling */
.ant-input,
.ant-input-password {
  border-radius: 8px;
  padding: 6px;
}
}

.recaptcha-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 640px) {
  .recaptcha-container {
    width: 100%;
    max-width: 280px; /* Limit the maximum width */
    padding: 0 10px; /* Add some padding to ensure it doesn't touch the edges */
  }
  .ant-form-item-explain-error {
    font-size: smaller;
}
}


