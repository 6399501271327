.tableRow {
    &:hover {
      background-color: #fafafa;
    }
  }
  
  .tableCell {
    /* padding: 10px; */
    text-align: left;
  }
  /* .actions {
    display: flex;
    justify-content: start;
    align-items: center;
}

.icon {
    cursor: pointer;
    margin: 0 10px;
} */

@media (max-width: 768px) {
  button {
  padding-inline: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  }
}

.grayRow {
  background-color: #f0f0f0;
}