.ant-btn-primary {
  background: #2D796D;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background: #25665c;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border:1px solid #2D796D;
  color:#2D796D
}
